<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Sitios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Sitios</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-3 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Sitios</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Geocercas"
                    data-toggle="tab"
                    href="#Geocercas"
                    v-if="form.id"
                    >Geocercas</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="codigo">Código Alterno</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="codigo"
                            v-model="form.codigo"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="nombre">Nombre</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="nombre"
                            v-model="form.nombre"
                            :class="
                              $v.form.nombre.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="sigla">Sigla</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="sigla"
                            v-model="form.sigla"
                            :class="
                              $v.form.sigla.$invalid ? 'is-invalid' : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="ciudad">Ciudad</label>
                          <v-select
                            :class="
                              $store.getters.getDarkMode ? 'dark-vselect' : ''
                            "
                            v-model="ciudad"
                            label="nombre"
                            :options="listasForms.ciudades"
                            placeholder="Ciudades"
                            @input="getSelectCiudades()"
                          ></v-select>
                        </div>
                      </div>
                      <div class="col-md-3" v-if="ciudad.id">
                        <div class="form-group">
                          <label for="ciudad">Departamento</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="departamento"
                            v-model="ciudad.departamento.descripcion"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="bloque">Bloque</label>
                          <v-select
                            :class="
                              $store.getters.getDarkMode ? 'dark-vselect' : ''
                            "
                            v-model="bloque"
                            label="nombre"
                            :options="listasForms.bloques"
                            placeholder="Bloques"
                            @input="getSelectBloques()"
                          ></v-select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="dirreccion">Dirección</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="dirreccion"
                            v-model="form.dirreccion"
                            :class="
                              $v.form.dirreccion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="latitud">Latitud</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="latitud"
                            v-model="form.latitud"
                            :class="
                              $v.form.latitud.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="longitud">Longitud</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="longitud"
                            v-model="form.longitud"
                            :class="
                              $v.form.longitud.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="kilometraje_postulacion"
                            >Tiempo Máximo Enturne <sup>(Horas)</sup></label
                          >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="tiempo_maximo_arribo"
                            v-model="form.tiempo_maximo_arribo"
                            :class="
                              $v.form.tiempo_maximo_arribo.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="kilometraje_postulacion"
                            >Kilometraje postulación</label
                          >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="kilometraje_postulacion"
                            v-model="form.kilometraje_postulacion"
                            :class="
                              $v.form.kilometraje_postulacion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="km_postulacion_add"
                            >Km postulación adicional</label
                          >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="km_postulacion_add"
                            v-model="form.km_postulacion_add"
                            :class="
                              $v.form.km_postulacion_add.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="tiempo_maximo_cambio"
                            >Tiempo máximo cambio <sup>(minutos)</sup></label
                          >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="tiempo_maximo_cambio"
                            v-model="form.tiempo_maximo_cambio"
                            :class="
                              $v.form.tiempo_maximo_cambio.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="horas_limite_solicitud"
                            >Límite de Solicitud <sup>(horas)</sup></label
                          >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="horas_limite_solicitud"
                            v-model="form.horas_limite_solicitud"
                            :class="
                              $v.form.horas_limite_solicitud.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="promedio_cargue"
                            >Promedio Cargue <sup>(vehículos)</sup></label
                          >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="promedio_cargue"
                            v-model="form.promedio_cargue"
                            :class="
                              $v.form.promedio_cargue.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="prom_cargue_planta"
                            >Promedio Cargue Planta Externa
                            <sup>(vehículos)</sup></label
                          >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="prom_cargue_planta"
                            v-model="form.prom_cargue_planta"
                            :class="
                              $v.form.prom_cargue_planta.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="tipo_sitio">Tipo de sitio</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.tipo_sitio"
                            :class="
                              $v.form.tipo_sitio.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="tipo_sitio in listasForms.tipo_sitios"
                              :key="tipo_sitio.numeracion"
                              :value="tipo_sitio.numeracion"
                            >
                              {{ tipo_sitio.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="cargues_hora">Cargues por hora</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="cargues_hora"
                            v-model="form.cargues_hora"
                            :class="
                              $v.form.cargues_hora.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="hora_inicial">Hora inicial Citas</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="hora_inicial"
                            v-model="form.hora_inicial"
                            :class="
                              $v.form.hora_inicial.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            v-mask="{
                              mask: '99:99',
                              hourFormat: '24',
                            }"
                            @input="validaHoras()"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="hora_final">Hora final Citas</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="hora_final"
                            :class="
                              $v.form.hora_final.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            v-mask="{
                              mask: '99:99',
                              hourFormat: '24',
                            }"
                            v-model="form.hora_final"
                            @input="validaHoras()"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="activo">Activo</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.activo"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="activo in listasForms.activos"
                              :key="activo.numeracion"
                              :value="activo.numeracion"
                            >
                              {{ activo.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="tipo_sitio">Venta / Comprado</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.comercial_producto"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="venta_comprado in listasForms.venta_comprado"
                              :key="venta_comprado.numeracion"
                              :value="venta_comprado.numeracion"
                            >
                              {{ venta_comprado.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="tipo_sitio">Tipo Liquidación</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.tipo_liquidacion"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="tipo_liquidacion in listasForms.tipos_liquidacion"
                              :key="tipo_liquidacion.numeracion"
                              :value="tipo_liquidacion.numeracion"
                            >
                              {{ tipo_liquidacion.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        class="form-group col-md-3 text-center"
                        v-if="$store.getters.can('admin.sitios.cobroPerdida')"
                      >
                        <label>Cobro por perdida de producto</label>
                        <input
                          disabled
                          type="checkbox"
                          class="form-control form-control-sm p-0"
                          v-model="form.notificacion_perdida_prod"
                          id="notificacion_perdida_prod"
                        />
                      </div>
                      <div
                        class="form-group col-md-3 text-center"
                        v-if="$store.getters.can('admin.sitios.cobroPerdida')"
                      >
                        <label>Validación tanques</label>
                        <input
                          type="checkbox"
                          class="form-control form-control-sm p-0"
                          v-model="form.check_tanque"
                          id="check_tanque"
                        />
                      </div>
                    </div>
                    <SitioProcesosInspecciones v-if="form.id" />
                    <SitioFactorCombustible v-if="form.id" />
                    <SitioValidacionTanques v-if="form.check_tanque" />
                  </div>
                </div>
                <div class="tab-pane fade" id="Geocercas" v-if="form.id">
                  <div class="card-body">
                    <SitioGeocerca v-if="form.id" />
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, helpers } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import SitioGeocerca from "./SitioGeocerca.vue";
import SitioProcesosInspecciones from "./SitioProcesosInspecciones.vue";
import SitioFactorCombustible from "./SitioFactorCombustible.vue";
import SitioValidacionTanques from "./SitioValidacionTanques.vue";
import Loading from "../../../../components/Loading";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "SitioForm",
  components: {
    vSelect,
    SitioGeocerca,
    SitioProcesosInspecciones,
    SitioFactorCombustible,
    SitioValidacionTanques,

    Loading,
  },
  data() {
    return {
      cargando: false,
      form: {
        check_tanque: false,
      },
      ciudad: {},
      bloque: {},
      accion: "",
      metodo: "",

      listasForms: {
        ciudades: [],
        tipo_sitios: [],
        activos: [],
        venta_comprado: [],
        productos: [],
      },
      modal: {
        title: "",
        accion: "",
      },
    };
  },

  validations: {
    form: {
      nombre: {
        required,
      },
      sigla: {
        required,
      },
      ciudad_id: {
        required,
      },
      bloque_id: {
        required,
      },
      dirreccion: {
        required,
      },
      latitud: {
        required,
      },
      longitud: {
        required,
      },
      tiempo_maximo_arribo: {
        required,
      },
      kilometraje_postulacion: {
        required,
      },
      km_postulacion_add: {
        required,
      },
      tiempo_maximo_cambio: {
        required,
      },
      horas_limite_solicitud: {
        required,
      },
      promedio_cargue: {
        required,
      },
      prom_cargue_planta: {
        required,
      },
      tipo_sitio: {
        required,
      },
      cargues_hora: {
        required,
      },
      hora_inicial: {
        required,
        timeFormat,
      },
      hora_final: {
        required,
        timeFormat,
      },
    },
  },
  methods: {
    async getTipoSitios() {
      await axios.get("/api/lista/18").then((response) => {
        this.listasForms.tipo_sitios = response.data;
      });
    },

    async getActivos() {
      await axios.get("/api/lista/117").then((response) => {
        this.listasForms.activos = response.data;
      });
    },

    async getVentaComprado() {
      await axios.get("/api/lista/173").then((response) => {
        this.listasForms.venta_comprado = response.data;
      });
    },

    getSelectCiudades() {
      this.form.ciudad_id = this.ciudad.id;
      this.form.departamento_id = this.ciudad.departamento_id;
    },

    async getCiudades() {
      await axios.get("/api/admin/ciudades/lista").then((response) => {
        this.listasForms.ciudades = response.data;
      });
    },

    getSelectBloques() {
      this.form.bloque_id = this.bloque.id;
    },

    async getBloques() {
      await axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    async getTipoLiquidacion() {
      await axios.get("/api/lista/200").then((response) => {
        this.listasForms.tipos_liquidacion = response.data;
      });
    },

    validaHoras() {
      if (this.form.hora_inicial && this.form.hora_final) {
        if (this.form.hora_inicial > this.form.hora_final) {
          this.form.hora_final = null;
          this.$swal({
            icon: "error",
            title: `La hora inicial no puede ser mayor a la hora final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/sitios",
          data: this.form,
        })
          .then((response) => {
            this.cargando = false;
            this.form = response.data;
            this.$swal({
              icon: "success",
              title: "El sitio se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/Admin/Sitios");
    },

    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.metodo = "PUT";
        this.ciudad = this.form.ciudad.nombre;
        this.bloque = this.form.bloque;
      } else {
        this.metodo = "POST";
        this.form.notificacion_perdida_prod = 1;
        this.form.check_tanque = 1;
      }
    },
  },

  async mounted() {
    this.cargando = true;
    await this.init();
    await this.getCiudades();
    await this.getBloques();
    await this.getTipoSitios();
    await this.getActivos();
    await this.getVentaComprado();
    await this.getTipoLiquidacion();
    this.cargando = false;
  },
};
</script>
